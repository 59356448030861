<template>
  <div class="template-equipment-profile">
    <div class="row">
      <div class="col-sm-12">
        <div class="card bg-primary">
          <div class="card-body profile-user-box">
            <div class="row">
              <div class="col-sm-8">
                <div v-if="!$apollo.queries.wings_by_pk.loading">
                  <info :wingInfo="wings_by_pk" :wingflights="flights" />
                </div>
              </div>
              <!-- end col-->
              <div class="col-sm-4">
                <div class="text-center mt-sm-0 mt-3 text-sm-right">
                  <router-link to="/admin/wing">
                    <button type="button" class="btn btn-light mr-1">
                      <i class="mdi mdi-arrow-left mr-1"></i> Back
                    </button>
                  </router-link>
                  <button
                    type="button"
                    class="btn btn-light ml-1"
                    @click="editPilotProfile()"
                  >
                    <i class="mdi mdi-account-edit mr-1"></i> Edit Profile
                  </button>
                </div>
              </div>
              <!-- end col-->
            </div>
            <!-- end row -->
          </div>
          <!-- end card-body/ profile-user-box-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5">
        <div v-if="!$apollo.queries.wings_by_pk.loading">
          <card :wingInfo="wings_by_pk" />
        </div>
      </div>
      <div class="col-lg-7">
        <div v-if="!$apollo.queries.wings_by_pk.loading">
          <flight :flights="flights" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_WING_INFO, GET_WING_FLIGHTS } from "@/graphql/queries/queries";
import info from "@/components/subcomponents/wing/wingInfo";
import card from "@/components/subcomponents/wing/card";
import flight from "@/components/subcomponents/wing/wingFlight";
export default {
  name: "wingProfile",
  components: { info, card, flight },
  data() {
    return {};
  },
  methods: {
    editPilotProfile() {
      this.$router.push({
        name: "wingAdd",
        params: { wingID: this.$route.params.wingId },
      });
    },
  },
  apollo: {
    wings_by_pk: {
      query: GET_WING_INFO,
      variables() {
        return {
          wing_id: this.$route.params.wingId,
        };
      },
    },
    flights: {
      query: GET_WING_FLIGHTS,
      variables() {
        return {
          wing_id: this.$route.params.wingId,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.template-equipment-profile {
  padding-top: 24px;
}
</style>
